import { useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router';
import useEarlyAccessFeatureSelector from 'app/hooks/useEarlyAccessFeatureSelector';
import is from '@sindresorhus/is';
const mapStateToRedirectPath1 = {
  enabled: 'test_region',
  pending: 'region',
  disabled: 'region',
  default: 'region'
};
const useEarlyAccessFeatureRedirect = () => {
  const betaDistrictPageConsent = useEarlyAccessFeatureSelector('feat_district_main_page');
  const history = useHistory();
  const {
    pathname
  } = useLocation();
  const path1 = pathname.split('/')[1];
  const {
    districtSlug
  } = useParams();
  useEffect(() => {
    const value = is.string(betaDistrictPageConsent) ? betaDistrictPageConsent : 'default';
    const redirectTo = mapStateToRedirectPath1[value];
    if (redirectTo !== path1) {
      history.replace("/".concat(redirectTo, "/").concat(districtSlug));
    }
  }, [betaDistrictPageConsent]);
};
export { useEarlyAccessFeatureRedirect };