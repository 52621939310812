import {useEffect} from 'react';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';

const useSponsoringTracking = (sponsor, category) => {
  useEffect(() => {
    sendEventToGA4('sponsoring_ad_impression', {
      sponsor: sponsor,
      category: category,
    });
  }, []);
};

export {useSponsoringTracking};
