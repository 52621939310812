import Linkable from 'app/models/Linkable';
import Sluggable from 'app/models/Sluggable';

export default class MatchLink implements Linkable {
  constructor(private readonly match: Sluggable) {}

  getLink(): string {
    return `/match/${this.match.slug}`;
  }
}
