import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useSponsoringTracking} from 'app/hooks/useSponsoringTracking';
import {useParams} from 'react-router';

const logoWidth = 90;
const logoHeight = 34;

const Container = styled.div`
  display: flex;
  width: ${logoWidth / 16}rem;
  height: ${logoHeight / 16}rem;
  justify-content: flex-end;

  a {
    display: inherit;
    width: inherit;
    height: inherit;
    justify-content: inherit;
    cursor: pointer;
    text-decoration: none;
  }
`;

const Logo = styled.img`
  align-self: flex-end;
  max-width: ${logoWidth / 16}rem;
  max-height: ${logoHeight / 16}rem;
`;

const HeaderSponsoring = ({category, className}) => {
  const {districtSlug} = useParams();
  const sponsoringPartner = {
    form: {
      img: {
        path: '/sponsoring/owayo-logo.svg',
        width: `${logoWidth}px`,
        height: 'auto',
      },
      sponsor: 'Owayo',
      link: 'https://www.owayo.de/fussball-trikots-selbst-gestalten.htm?utm_source=fupa&utm_medium=logo&utm_campaign=powerranking',
    },
    match_day_results: {
      img: {
        path: '/sponsoring/avp_logo.jpg',
        width: `${logoWidth}px`,
        height: 'auto',
      },
      sponsor: 'AVP Autoland',
      link: 'https://www.avp-autoland.de/',
      districts: ['niederbayern', 'niederbayern-ost', 'niederbayern-west'],
    },
  };

  const showSponsoring = useMemo(() => {
    const sponsoring = sponsoringPartner[category];
    if (!sponsoring) {
      return false;
    }
    if (!sponsoring.districts) {
      return true;
    }
    return sponsoring.districts.includes(districtSlug);
  }, [category, districtSlug]);

  if (showSponsoring) {
    useSponsoringTracking(sponsoringPartner[category].sponsor, category);

    return (
      <Container className={className}>
        <a data-google-interstitial='false' target='_blank' href={sponsoringPartner[category].link} rel='nofollow'>
          <Logo
            src={sponsoringPartner[category].img.path}
            width={sponsoringPartner[category].img.width}
            height={sponsoringPartner[category].img.height}
            alt={sponsoringPartner[category].sponsor}
          />
        </a>
      </Container>
    );
  } else {
    return null;
  }
};

export {HeaderSponsoring};
