import {useEffect, useMemo, useRef, useState} from 'react';
import {IDistrictMainPageTrackingProps} from 'app/components/district/news/DistrictMainPageTracking';

export const useSwipeableViewsActiveStep = (
  views: any[],
  trackDataCallback?: (data: IDistrictMainPageTrackingProps) => void
) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const maxSteps = useMemo(() => views.length - 1, [views.length]);
  const maxActiveStepIndex = useRef(0);
  const touched = useRef(false);

  useEffect(() => {
    return () => {
      if (touched.current) {
        trackDataCallback?.({
          viewedPageIndex: maxActiveStepIndex.current,
          totalPagesCount: views.length,
        });
      }
    };
  }, []);

  const handleNext = () => {
    touched.current = true;
    if (activeStep < maxSteps) {
      maxActiveStepIndex.current =
        maxActiveStepIndex.current < activeStep + 1 ? activeStep + 1 : maxActiveStepIndex.current;
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const handleIndexChange = (index: number) => {
    setActiveStep(index);
  };

  useEffect(() => {
    if (activeStep > maxSteps) {
      setActiveStep(maxSteps);
    }
  }, [maxSteps]);

  return {activeStep, maxSteps, handleNext, handleBack, handleIndexChange};
};
